import { io } from 'socket.io-client';
const API_URL = process.env.VUE_APP_API_URL;

let socket = null;

export function createSocket() {
    if (!socket) {
        socket = io(API_URL, {
            auth: {
                token: localStorage.getItem('token')
            }
        });
    }
    return socket;
}

export function getSocket() {
    if (!socket) {
        socket = createSocket();
    }

    return socket;
}

export function disconnectSocket() {
    if (socket) {
        socket.disconnect();
        socket = null;
    }
}
