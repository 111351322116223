import { createRouter, createWebHistory } from 'vue-router'
import Swal from 'sweetalert2'
import axios from 'axios'
import dbUtils from "@/modules/dbUtils";
import utils from "@/modules/utils";
const API_URL = process.env.VUE_APP_API_URL;

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/firstLogin',
    name: 'firstLogin',
    component: () => import('../views/FirstLoginView.vue')
  },
  {
    path: '/profil',
    name: 'profil',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../pages/User/ProfileClient.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true
    },
    component: () => import('../pages/User/HomeClient.vue')
  },
  {
    path: '/newAcc',
    name: 'newAcc',
    meta: {
      requiresAuth: true,
      notFor: true
    },
    component: () => import('../pages/Accord/NewAccord.vue')
  },
  {
    path: '/searchAcc',
    name: 'search',
    meta: {
      requiresAuth: true
    },
    component: () => import('../pages/Accord/SearchAccord.vue')
  },
  {
    path: '/pendingAcc',
    name: 'pendingAcc',
    meta: {
      requiresAuth: true
    },
    component: () => import('../pages/PendingAccord.vue')
  },
  {
    path: '/pendingMess',
    name: 'pendingMess',
    meta: {
      requiresAuth: true
    },
    component: () => import('../pages/PendingMessage.vue')
  },
  {
    path: '/readAcc',
    name: 'readAcc',
    meta: {
      requiresAuth: true
    },
    component: () => import('../pages/Accord/ReadAccord.vue')
  },
  {
    path: '/adminDash',
    name: 'adminDash',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    component: () => import('../pages/Admin/AdminDash.vue')
  },
  {
    path: '/mailerDash',
    name: 'mailerDash',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    component: () => import('../pages/Admin/MailerDash.vue')
  },
  {
    path: '/DevDash',
    name: 'DevDash',
    meta: {
      requiresAuth: true,
      requiresDev: true,
    },
    component: () => import('../pages/Admin/DevDash.vue')
  },
  {
    path: '/exportCSV',
    name: 'exportCSV',
    meta: {
      requiresAuth: true,
      requiresSogedis: true,
    },
    component: () => import('../pages/Admin/ExportCSV.vue')
  },
  {
    path: '/importCSV',
    name: 'importCSV',
    meta: {
      requiresAuth: true,
      requiresSogedis: true,
    },
    component: () => import('../pages/Admin/ImportCSV.vue')
  },
  {
    path: '/viewLog',
    name: 'viewLog',
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    component: () => import('../pages/Admin/ViewLogPage.vue')
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta: {
      requiresAuth: true
    },
    component: () => import('../pages/MaintenancePage.vue')
  },
  {
    path: '/newRMA',
    name: 'newRMA',
    meta: {
      requiresAuth: true,
      requiresMetro: true,
      notFor: true,
    },
    component: () => import('../pages/RMA/NewRMA.vue')
  },
  {
    path: '/readRMA',
    name: 'readRMA',
    meta: {
      requiresAuth: true,
      requiresMetro: true
    },
    component: () => import('../pages/RMA/ReadRMA.vue')
  },
  {
    path: '/searchRMA',
    name: 'searchRMA',
    meta: {
      requiresAuth: true,
      requiresMetro: true
    },
    component: () => import('../pages/RMA/SearchRMA.vue')
  },
  {
    path: '/mentionsLegales',
    name: 'mentionsLegales',
    meta: {
      requiresAuth: true
    },
    component: () => import('../pages/MentionsLegales.vue')
  },
  { path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
    try {
      if (!localStorage.getItem('token') || localStorage.getItem('token') === '' || localStorage.getItem('token') === null)
      {
        localStorage.removeItem('token');
        localStorage.removeItem('vuex');
        localStorage.removeItem('_secure__ls__metadata');
        if(to.name !== "login") {
          next({ name: 'login' });
        } else {
          next();
        }
      } else {
        const response = await axios.post(API_URL + 'login/isValidToken',   {
          token: localStorage.getItem('token')
        });
        if (!response)
          next({ name: 'login' });

        const isConstructor =  await dbUtils.isConstructor();

        // if (to.meta.requiresAuth && !response.data) {
        //   localStorage.removeItem('token');
        //   localStorage.removeItem('vuex');
        //   localStorage.removeItem('_secure__ls__metadata');
        //   document.location.href="/login"; // Redirigez vers la page de connexion si l'utilisateur n'est pas authentifié
        // }

        if(response.data) {
          let decodedToken = "";
          if(typeof response.data === "string") {
            localStorage.setItem('token', response.data)
            decodedToken = utils.decodeToken(response.data);
          }
          else if(response.data === true) {
            const token = localStorage.getItem('token');
            decodedToken = utils.decodeToken(token);
          } else {
            const token = response.data.token;
            localStorage.setItem('token', token);
            decodedToken = utils.decodeToken(token);
          }

          let parametre = await dbUtils.getUserInfos(decodedToken.id);
          parametre = (parametre.parametre ? (JSON.parse((parametre).parametre)) : null)
          let isConstructorRMA = false;
          if(parametre && "constructeurSociete" in parametre) {
            let paramSociete = parametre.constructeurSociete;
            let societesForRMA = ['6', '7'];
            for (let i = 0; i < paramSociete.length; i++) {
              if (societesForRMA.includes(paramSociete[i])) {
                isConstructorRMA = true;
              }
            }
          }

          if(to.meta.requiresAuth && (!to.meta.requiresAdmin || !to.meta.requiresMetro || !to.meta.requiresSogedis) && !to.meta.notFor) {
            next()
          }

          const notForRoles = [3];
          if(to.meta.notFor && notForRoles.includes(decodedToken.role)) {
            Swal.fire({
              icon: 'error',
              title: 'Accès refusé',
              text: 'Vous n\'avez pas accès à cette ressource',
            })
                .then(() => {
                  router.go(-1);
                });
          } else {
            next();
          }

          const roleMetro = (await dbUtils.getConfigs('ROLE_METRO')).split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));
          if(to.meta.requiresMetro && (roleMetro.includes(decodedToken.role) || (isConstructor && isConstructorRMA))) {
            next()
          } else if(to.meta.requiresMetro && !(roleMetro.includes(decodedToken.role)) && !(isConstructor && isConstructorRMA)) {
            Swal.fire({
              icon: 'error',
              title: 'Accès refusé',
              text: 'Vous n\'avez pas accès à cette ressource',
            })
                .then(() => {
                  router.go(-1);
                });
          }

          const roleSogedis = (await dbUtils.getConfigs('ROLE_FROM_SOGEDIS')).split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));
          if(to.meta.requiresSogedis && roleSogedis.includes(decodedToken.role)) {
            next()
          } else if(to.meta.requiresSogedis && !(roleSogedis.includes(decodedToken.role))) {
            Swal.fire({
              icon: 'error',
              title: 'Accès refusé',
              text: 'Vous n\'avez pas accès à cette ressource',
            })
                .then(() => {
                  router.go(-1);
                });
          }

          const roleAdmin = (await dbUtils.getConfigs('ROLE_ADMIN')).split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));
          if(to.meta.requiresAdmin && roleAdmin.includes(decodedToken.role)) {
            next()
          } else if(to.meta.requiresAdmin && !(roleAdmin.includes(decodedToken.role))) {
            Swal.fire({
              icon: 'error',
              title: 'Accès refusé',
              text: 'Vous n\'avez pas accès à cette ressource',
            })
                .then(() => {
                  router.go(-1);
                });
          }

          if(to.meta.requiresDev && utils.isPlOrTl()) {
            next()
          } else if(to.meta.requiresDev && !utils.isPlOrTl()) {
            Swal.fire({
              icon: 'error',
              title: 'Accès refusé',
              text: 'Vous n\'avez pas accès à cette ressource',
            })
                .then(() => {
                  router.go(-1);
                });
          }

        } else if(to.name !== "firstLogin") {
          localStorage.removeItem('token');
          localStorage.removeItem('vuex');
          localStorage.removeItem('_secure__ls__metadata');
          if(to.name !== "login") {
            router.go('/login')
          }
        }
      }

    } catch (error) {
      console.log(error)
      if(error.code === "ERR_NETWORK")
        Swal.fire({
          icon: 'error',
          title: 'Impossible de joindre l\'API',
          text: 'Veuillez contacter Sogedis',
        })
      else {
        localStorage.removeItem('token');
        localStorage.removeItem('vuex');
        localStorage.removeItem('_secure__ls__metadata');
        router.go('/login')
      }
    }
});

export default router
