<template>
  <v-navigation-drawer
      class="NavDrawerContainer"
      v-if="!isLoading"
  >
    <v-list nav class="NavDrawer">

      <h4 class="username">{{ username }}</h4>

      <v-list-item color="hover" title="Accueil" value="home" class="listItem" to="/">
        <template v-slot:prepend>
          <v-icon icon="mdi-home" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <DividerMenu title="Accords" v-show="accordMenu || isConstructor"/>

      <v-list-item color="hover" title="Nouvelle demande" value="newAcc" class="listItem" to="/newAcc"
                   v-show="accordMenu && userRole != 3">
        <template v-slot:prepend>
          <v-icon icon="mdi-text-box-plus" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <v-list-item color="hover" title="Rechercher un dossier" value="searchAcc" class="listItem" to="/searchAcc"
                   v-show="accordMenu || isConstructor">
        <template v-slot:prepend>
          <v-icon icon="mdi-file-search" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <!--            <v-list-item color="hover" title="Dossier en attente" value="readAcc" class="listItem" to="/pendingAcc">-->
      <!--                <template v-slot:prepend>-->
      <!--                    <v-icon icon="mdi-file-clock" color="hover" class="icon-active"></v-icon>-->
      <!--                    <span v-if="pendingAccords && pendingAccords > 0" class="badge">{{ pendingAccords }}</span>-->
      <!--                </template>-->
      <!--            </v-list-item>-->

      <DividerMenu title="RMA" v-show="metroMenu || (isConstructor && isConstructorRMA)"/>

      <v-list-item color="hover" title="Nouveau RMA" value="newRMA" class="listItem" to="/newRMA" v-show="metroMenu && userRole != 3">
        <template v-slot:prepend>
          <v-icon icon="mdi-text-box-plus" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <v-list-item color="hover" title="Rechercher un RMA" value="searchRMA" class="listItem" to="/searchRMA"
                   v-show="metroMenu || (isConstructor && isConstructorRMA)">
        <template v-slot:prepend>
          <v-icon icon="mdi-file-search" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <!-- ======================================================== -->
      <DividerMenu title="Administration" v-show="adminMenu || userRole == 3"/>

      <v-list-item color="hover" title="Gestion de l'application" value="administration" class="listItem"
                   to="/adminDash" v-show="adminMenu">
        <template v-slot:prepend>
          <v-icon icon="mdi-wrench" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <v-list-item color="hover" title="Gestion du Mailer" value="mailer" class="listItem"
                   to="/mailerDash" v-show="adminMenu">
        <template v-slot:prepend>
          <v-icon icon="mdi-email-sync" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <v-list-item color="hover" title="Export CSV" value="export" class="listItem" to="/exportCSV" v-show="adminMenu || userRole == 3">
        <template v-slot:prepend>
          <v-icon icon="mdi-database-export" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <v-list-item color="hover" title="Import CSV" value="import" class="listItem" to="/importCSV" v-show="adminMenu || userRole == 3">
        <template v-slot:prepend>
          <v-icon icon="mdi-database-import" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <v-list-item color="hover" title="Voir les logs" value="logs" class="listItem" to="/viewLog" v-show="adminMenu">
        <template v-slot:prepend>
          <v-icon icon="mdi-information" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <!-- ======================================================== -->

      <DividerMenu title="Développeur" v-if="devMenu"/>

      <v-list-item color="hover" title="Outils" value="tool" class="listItem"
                   to="/devDash" v-show="devMenu">
        <template v-slot:prepend>
          <v-icon icon="mdi-lock" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <!-- ======================================================== -->

      <DividerMenu title="Profil"/>

      <v-list-item color="hover" title="Paramètres" value="profil" class="listItem" to="/profil">
        <template v-slot:prepend>
          <v-icon icon="mdi-account-cog" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

      <v-list-item color="hover" title="Mentions légales" value="mentionsLegales" class="listItem" to="/mentionsLegales">
        <template v-slot:prepend>
          <v-icon icon="mdi-scale-balance" color="hover" class="icon-active"></v-icon>
        </template>
      </v-list-item>

    </v-list>

  </v-navigation-drawer>
</template>

<style>
.v-navigation-drawer__content {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch; /* Pour un défilement fluide sur iOS */
}

.v-navigation-drawer__content::-webkit-scrollbar {
  display: none; /* Masquer la barre de défilement sur Webkit */
}

.v-navigation-drawer__content {
  -ms-overflow-style: none; /* Masquer la barre de défilement sur Internet Explorer et Edge */
  scrollbar-width: none; /* Masquer la barre de défilement sur Firefox */
}
</style>
<script>
import axios from 'axios';
import DividerMenu from './DividerMenu';
import dbUtils from "@/modules/dbUtils";
import utils from "@/modules/utils";

const API_URL = process.env.VUE_APP_API_URL;

export default {
  components: {
    DividerMenu
  },
  data: () => ({
    drawer: false,
    group: null,
    username: '',
    userRole: null,
    userId: null,

    adminMenu: false,
    devMenu: false,
    metroMenu: false,
    accordMenu: false,
    sogedisMenu: false,
    pendingAccords: null,
    isConstructor: false,
    paramSociete: [],
    isConstructorRMA: false,
    isLoading: true
  }),

  created() {
    this.$eventBus.on('reloadPendingAccord', this.handleReloadPendingAccord);
  },

  beforeUnmount() {
    this.$eventBus.off('reloadPendingAccord', this.handleReloadPendingAccord);
  },

  computed: {
    shouldDisplayNavDrawer() {
      return window.innerWidth >= 768;
    }
  },

  watch: {
    group() {
      this.drawer = false
    },
  },

  async mounted() {
    const sToken = localStorage.getItem('token');
    if (!sToken || sToken === '' || sToken === null)
      return false;

    await this.hideMenu();
    this.getPendingAccords();

    if (window.innerWidth < 1280) {
      document.querySelectorAll('.listItem').forEach(item => {
        item.addEventListener('click', () => {
          const navDrawer = document.querySelector('.NavDrawerContainer');
          navDrawer.style.transform = 'translateX(-100%)';
        });
      });
    }
    this.userId = (utils.decodeToken(sToken)).id;
    if(this.username !== "")
      this.isLoading = false;
  },

  methods: {
    async handleReloadPendingAccord() {
      await this.getPendingAccords();
    },

    async hideMenu() {
      try {
        const token = localStorage.getItem('token')
        const tokenData = utils.decodeToken(token);
        const response = await axios.get(API_URL + `user/getUserInfo/${tokenData.id}`,
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
        )
        this.userRole = tokenData.role;

        if (token !== response.data.token) {
          throw new Error('Le token est invalide');
        } else {
          this.username = response.data.nom + " " + response.data.prenom

          if (utils.isPlOrTl()) {
            this.devMenu = true;
            this.adminMenu = true;
            this.sogedisMenu = true;
            this.metroMenu = true;
            this.accordMenu = true;
          }
          const roleFromSogedis = (await dbUtils.getConfigs('ROLE_FROM_SOGEDIS')).split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));
          if (roleFromSogedis.includes(response.data.role)) {
            this.isSogedis = true;
            this.sogedisMenu = true;
            this.metroMenu = true;
            this.accordMenu = true;
          }
          const roleAdmin = (await dbUtils.getConfigs('ROLE_ADMIN')).split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));
          if (roleAdmin.includes(response.data.role)) {
            this.adminMenu = true;
            this.sogedisMenu = true;
            this.metroMenu = true;
            this.accordMenu = true;
          }
          const roleMetro = (await dbUtils.getConfigs('ROLE_METRO')).split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));
          if (roleMetro.includes(response.data.role)) {
            this.metroMenu = true;
          }
          const roleAccord = (await dbUtils.getConfigs('ROLE_ACCORD')).split(',').map(extension => parseInt(extension.trim().replace(/'/g, ''), 10));
          if (roleAccord.includes(response.data.role)) {
            this.accordMenu = true;
          }

          this.isConstructor = await dbUtils.isConstructor();
          let parametre = await dbUtils.getUserInfos(tokenData.id);
          parametre = (parametre.parametre ? (JSON.parse((parametre).parametre)) : null);

          if(parametre && "constructeurSociete" in parametre) {
            this.paramSociete = parametre.constructeurSociete;
            let societesForRMA = ['6', '7'];
            for (let i = 0; i < this.paramSociete.length; i++) {
              if (societesForRMA.includes(this.paramSociete[i])) {
                this.isConstructorRMA = true;
              }
            }
          }
        }

      } catch (error) {
        console.error('Erreur lors de l\'envoi du message :', error);
      }
    },

    async getPendingAccords() {
      try {
        const response = await axios.get(API_URL + 'accord/getPendingAccords', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
        });
        this.pendingAccords = response.data.length;
      } catch (error) {
        console.log('Erreur lors de la récupération des messages :', error);
      }
    },
  }
}
</script>


<style scoped>

/* .router-container {
   background-image: url('https://images.unsplash.com/photo-1519681393784-d120267933ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1124&q=100') !important;
   background-position: ;
} */

</style>
