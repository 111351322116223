import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import store from './store'
import eventBus from './mitt/eventBus'
import dotenv from 'dotenv';
import 'path-browserify';
import globalFunction from "@/plugins/globalFunction";

dotenv.config();
loadFonts();

if (process.env.VUE_APP_DEV_STYLE === 'style_dev') {
    require('./assets/style_dev.css');
}
else {
    require('./assets/style.css');
}

const app = createApp(App);

app.use(router)
   .use(vuetify)
   .use(globalFunction)
   .use(store);

app.config.globalProperties.$eventBus = eventBus;
app.mount('#app');
