import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";

const secureLS = new SecureLS({ isCompression: false });

const store = createStore({
  state: {
    accordId: null,
    userId: null,
    rmaId: null,
    userCount: 0,
  },
  mutations: {
    setAccordId(state, accordId) {
      state.accordId = accordId;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setRMAId(state, rmaId) {
      state.rmaId = rmaId;
    },
    setUserCount(state, userCount) {
      state.userCount = userCount;
    }
  },
  actions: {
    updateAccordId({ commit }, accordId) {
      commit('setAccordId', accordId);
    },
    updateUserId({ commit }, userId) {
      commit('setUserId', userId);
    },
    updateRMAId({ commit }, rmaId) {
      commit('setRMAIdId', rmaId);
    },
    updateUserCount({ commit }, userCount) {
      commit('setUserCount', userCount);
    },
  },
  getters: {
    getAccordId: state => state.accordId,
    getUserId: state => state.userId,
    getRMAId: state => state.rmaId,
    getUserCount: state => state.userCount
  },
  modules: {
    // MODULE ICI
  },
  plugins: [createPersistedState({
    storage: {
      getItem: key => {
        try {
          return secureLS.get(key);
        } catch (error) {
          console.error(`Erreur de parsing JSON pour la clé ${key}:`, error);
          return null;
        }
      },
      setItem: (key, value) => {
        secureLS.set(key, value);
      },
      removeItem: key => {
        secureLS.remove(key);
      }
    }
  })]
});

export default store;
