import Swal from "sweetalert2";
import utils from "@/modules/utils";

export default {
    install(app) {
        app.config.globalProperties.$dump = (variable, stop = false) => {
            const bIsTheo = utils.isTheo(false);
            if (bIsTheo)
            {
                Swal.fire({
                    title: 'Dump',
                    html: `<pre style="text-align: left">${JSON.stringify(variable, null, 2)}</pre>`,
                    icon: 'info',
                    showConfirmButton: true,
                }).then(() => {
                    if (stop)
                        throw new Error('Execution stopped'); // Stoppe l'exécution après la fermeture de la popup
                });
            }
        };
    },
};